/** @jsx jsx */
import React from "react"
import { jsx } from '@emotion/react'
import { card, col_card, SectionStyled } from '../assets/styles/EducacaoInfantilSliderContainer.styles'
import { Col, Container, Row } from 'react-bootstrap'
import CardSlider from './cardsSliderFundamental'
import MapLocation from '../assets/icons/mapLocationPurple-icon.svg'

const EducacaoInfantilSliderContainer = () => {
  return (
    <section css={SectionStyled} id="estrutura-school">
      <Container fluid>
        <Row>
          <Col
            css={col_card}
            data-aos="fade-right"
            lg={4}
            md={4}
            sm={12}
          >
            <div css={card}>
              <h2>
                Personalizamos a educação em um ambiente coletivo!
              </h2>
              <h3>
                <img src={MapLocation} /> Ensino Fundamental - QI 11 - Lago Sul
              </h3>
              <p>
                Uma estrutura de 7.600m², pensada na união dos diferentes focos de interesse: tecnologia, criatividade, natureza, acessibilidade, conforto e leveza. Com salas amplas, o prédio permite que os alunos trafeguem com segurança e senso de orientação.
              </p>
            </div>
          </Col>
          <Col lg={8} md={8} sm={12}>
            <CardSlider />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default EducacaoInfantilSliderContainer
