/** @jsx jsx */
import { css } from "@emotion/react";

export const SectionStyled = css`
  height: 100%;
  max-height: 724px;
  padding-block: 73px;
  padding-left: 165px;
  background: #F5F5F5;
  @media (max-width: 768px) {
    max-height: 1234px;
    padding: 72px 16px;
  }
  @media (min-width: 905px) {
    scroll-margin-top: 100px;
  }
  @media (max-width: 900px) {
    scroll-margin-top: 0px;
  }
`;

export const card = css`
  background: #fff;
  border-radius: 32px;
  padding: 70px 29px;
  max-width: 445px;
  font-family: "montserrat", sans-serif;
  @media (min-width: 905px) {
    scroll-margin-top: 300px;
  }
  @media (max-width: 900px) {
    scroll-margin-top: 100px;
  }
  h2 {
    color: #3F3F3F;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 36px */
    letter-spacing: -1.8px;
    margin-bottom: 24px;
  }
  h3 {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #5315FF;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin-bottom: 40px;
  }
  p {
    color: #7E7E7E;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin-bottom: 0px;
  }
  @media (max-width: 768px) {
    margin-bottom: 72px;
  }
`;

export const col_card = css`
  display: flex;
  justify-content: flex-end;
  padding: 0;
`;

