import React from "react";
import Layout from "@/components/layout";
import Footer from "@/components/footer";
import HeaderTitle from "@/components/HeaderTitleEFI";
import UmOlharParaPessoas from "@/components/UmOlharParaPessoasContent";
import EstimulacaoSegurancaAfeto from "@/components/EstimulacaoSegurancaAfetoContent";
import CTAMaisTempoParaObem from "@/components/CTAMaisTempoParaObemEF";
import ImagineUmaEscola from "@/components/EscolaQueCuida";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import HeaderOne from '@/components/header-one';
import EducacaoFundamentalSliderContainer from '@/components/EducacaoFundamentalSliderContainer';
import SEO from '../components/seo'

const EnsinoFundamentalIPage = () => {
  return (
    <MenuContextProvider> 
      <SearchContextProvider>
        <Layout>
          <SEO
            title="Ensino Fundamental I em Brasília: Base sólida para o futuro | Kingdom School"
            description="O Ensino Fundamental I da Kingdom proporciona uma base sólida em português, matemática e inglês. Agende uma visita!m para a Educação Infantil."
          />
          <HeaderOne />
          <HeaderTitle />
          <UmOlharParaPessoas />
          <EducacaoFundamentalSliderContainer />
          <EstimulacaoSegurancaAfeto />
          <ImagineUmaEscola />
          <CTAMaisTempoParaObem />
          <Footer /> 
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default EnsinoFundamentalIPage;
