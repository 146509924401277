/** @jsx jsx */
import React from "react";
import { jsx } from '@emotion/react'
import { Fragment } from 'react'
import { Parallax } from 'react-parallax';
import { Link } from "gatsby";
import BgSection from "../assets/images/LP-kingdom/mais-tempo-para-o-bem-2.png";

import {ctaMaisTempoParaOBem } from '../assets/styles/AboutTwo.styles'

const CTAMaisTempoParaOBemEF = () => {

  return (
    <Fragment>
      <Parallax bgImage={BgSection} bgImageAlt="the cat" strength={300}>
        <section css={ctaMaisTempoParaOBem}>
          <div className="div-align">
            <h2>Mais tempo para o bem</h2>
            <p>Após o horário regular, o aluno pode se dedicar a atividades extracurriculares voltadas para artes e esportes.</p>
            <Link to="/a-escola#abordagem" target="_blank">Conheça o contraturno</Link>
          </div>
          <div className="parallax-element"></div>
        </section>
      </Parallax>
    </Fragment>
  )
}

export default CTAMaisTempoParaOBemEF
