/** @jsx jsx */
import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { jsx } from "@emotion/react";
import {
  imagineUmaEscola,
  content,
  iconsBg4,
  desktop,
  mobile
} from "../assets/styles/PrimeiroPasso.style";

import ImgEnsinoIntencional from "../assets/images/LP-kingdom/escola-que-cuida.png";

const PrimeiroPasso = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
        setScrollPosition(window.scrollY/2);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, []);

  return (
    <section css={imagineUmaEscola} id="imagine-uma-escola">
      <Container fluid>
        <Row className="title-content">
          <Col css={content} xs={12} lg={8} md={12} sm={12}>
            <div>
              <h2 style={{ marginBottom: 56, width: '100%', maxWidth: 475 }}>Imagine uma escola que cuida do seu filho por dentro e por fora</h2>
              <h3 style={{ marginBottom: 16 }}>Salas de aula-laboratório</h3>
              <p style={{ marginBottom: 40 }}>A cada disciplina os alunos se direcionam para a sala da matéria que irão trabalhar de acordo com a grade-horária. Essa prática traz para o aluno uma maior capacidade de adaptação, organização, interesse e facilidade no aprendizado.</p>
              <h3 style={{ marginBottom: 16 }}>Alimentação de qualidade</h3>
              <p>Na Kingdom, não adotamos cantina. Acreditamos que hábitos se constroem no dia a dia e com a exposição a aquilo que é bom. Nossos alunos têm acesso a uma alimentação livre de conservantes, corantes, industrializados, sucos adoçados e produtos de baixa qualidade nutricional. Além disso, ao longo de todo período de aula eles podem consumir frutas variadas disponíveis nos corredores entre os laboratórios. Esse consumo garante a baixa de ansiedade, melhora a saciedade e estimula o consumo do bom alimento.</p>
            </div>
          </Col>
          <Col css={desktop} className="img-container px-0" lg={4} md={12} sm={12}>
          <div
            // css={iconsBg4}
            // className="bg-image"
          >
            <img src={ImgEnsinoIntencional}></img>
          </div>
        </Col>
        </Row>
      </Container>
      <Col css={mobile} className="img-container px-0" xs={12} lg={5} md={12} sm={12}>
          <div
            // css={iconsBg4}
            // className="bg-image"
          >
            <img src={ImgEnsinoIntencional}></img>

          </div>
        </Col>
    </section>
  );
};

export default PrimeiroPasso;
