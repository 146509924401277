/** @jsx jsx */
import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { jsx } from "@emotion/react";
import { Link } from "gatsby";
import {
  olharParaPessoas,
  content,
  iconsBg2,
  desktop,
  mobile
} from "../assets/styles/PrimeiroPasso.style";

const PrimeiroPasso = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
        setScrollPosition(window.scrollY/2);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, []);

  return (
    <section css={olharParaPessoas}>
      <Col css={mobile} className="img-container px-0" lg={5} md={12} sm={12}>
          <div
            css={iconsBg2}
            className="bg-image"
          ></div>
        </Col>
      <Container fluid>
        <Row className="title-content">
          <Col css={content} lg={7} md={12} sm={12}>
            <div className="col-content">
              <h2>Um olhar para pessoas e não somente para métodos</h2>
              <p>A Kingdom usa a neuroeducação para compreender as diferentes formas de aprendizado e as abordagens necessárias para acessar os alunos em suas individualidades. Na Kingdom School, entender os processos educacionais não é suficiente para alcançar bons resultados. Afinal, métodos não ensinam; pessoas ensinam através de métodos!</p>
              <p>Entregamos uma educação de ensino tradicional, baseada na BNCC e alinhada com as competências do século 21. Nossa rotina pedagógica é realizada em 4h30min do 1º ao 3º ano e de 5h a partir do 4º ano.</p>
              <div className="button-link">
                <Link to="/a-escola#abordagem" target="_blank">Conheça a metodologia Kingdom</Link>
              </div>
            </div>
          </Col>
          <Col css={desktop} className="img-container px-0" lg={5} md={12} sm={12}>
          <div
            css={iconsBg2}
            className="bg-image"
          ></div>
        </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PrimeiroPasso;
